import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import "./PopupMessage.css";

const PopupMessage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 10000); // 40 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.init("lvmHbjiJ-ympdLWPu");
    emailjs.send("service_email", "template_email_id", formData).then(
      (result) => {
        setAlert({ type: "success", message: "Message sent successfully!" });
        setFormData({ name: "", email: "", phone: "", message: "" });
      },
      (error) => {
        setAlert({
          type: "error",
          message: "Failed to send the message. Please try again later.",
        });
      }
    );
  };

  return (
    <>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            <button
              onClick={handleClose}
              className="popup-close-btn"
              aria-label="Close"
            >
              &times;
            </button>
            <h2 className="popup-title">Contact Us</h2>
            <p className="popup-message">
              Please write your message and contact information below. We will
              respond as soon as possible.
            </p>
            <form className="popup-form" onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                className="popup-input"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="email"
                className="popup-input"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <input
                type="tel"
                name="phone"
                className="popup-input"
                placeholder="Enter your phone number"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              <textarea
                name="message"
                className="popup-input"
                placeholder="Type your message here!"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>

              <div className="popup-checkbox-container">
                <input type="checkbox" id="agreement" required />
                <label htmlFor="agreement">
                  I confirm that I’ve read and agree to{" "}
                  <a href="#">Privacy Policy</a>.
                </label>
              </div>
              <button type="submit" className="popup-submit-btn">
                Submit
              </button>
            </form>
            {alert && (
              <div className={`popup-alert ${alert.type}`}>{alert.message}</div>
            )}
            <button className="popup-remind-btn" onClick={handleClose}>
              Remind me later
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupMessage;
