// src/App.js
import React from "react";
import About from "./About";
import "./App.css";
import Contact from "./Contact";
import Footer from "./Footer";
import Home from "./Home";
import Navbar from "./Navbar";
import PopupMessage from "./PopupMessage";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div id="home">
        <Home />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="contact">
        <Contact />
      </div>
      {/* <div className="coming-soon-container">
        <Typography className="coming-soon">Coming Soon....</Typography>
      </div> */}
      {/* <div id="services">
      <Services />
      </div>
      <Footer /> */}
      <Footer />
      <PopupMessage />
    </div>
  );
}

export default App;
