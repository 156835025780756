import React from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactComponent as Google } from "./assets/Google Cloud.svg";
import { ReactComponent as Azure } from "./assets/Microsoft Azure.svg";
import { ReactComponent as Aws } from "./assets/AWS Services.svg";
import { ReactComponent as Docker } from "./assets/Docker.svg";
import { ReactComponent as Visual } from "./assets/Visual Studio.svg";
import { ReactComponent as Git } from "./assets/Git.svg";
import { ReactComponent as Hero } from "./assets/Hero.svg";
import { ReactComponent as Iconcheckmark } from "./assets/icons/checkIcon.svg";
import { ReactComponent as Dataeaze } from "./assets/Dataeaze.svg";
import { ReactComponent as Enerscript } from "./assets/Enerscript.svg";
import { ReactComponent as NTT } from "./assets/NTT DATA.svg";
import { ReactComponent as Tata } from "./assets/TATA AIG.svg";
import "./About.css";

const About = () => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container sx={{ paddingTop: "50px", maxWidth: "100% !important" }}>
      {isSmallDevice ? (
        // Mobile HTML structure
        <Box>
          <Typography
            sx={{
              fontSize: "24px",
              color: "#FF9357",
              fontWeight: "500",
              textAlign: "center",
            }}
            variant="h5"
          >
            OUR TECHNOLOGIES
          </Typography>
          <Typography
            sx={{
              fontSize: "28px",
              padding: "20px",
              fontWeight: "500",
              textAlign: "center",
            }}
            variant="h6"
          >
            Trusted Software Service Providers
          </Typography>
          <Box className="train-container" sx={{ overflowX: "auto" }}>
            <Box
              className="train-content"
              sx={{ display: "flex", flexWrap: "nowrap" }}
            >
              {[
                <Google className="image" />,
                <Aws className="image" />,
                <Azure className="image" />,
                <Git className="image" />,
                <Docker className="image" />,
                <Visual className="image" />,
              ].map((Icon, index) => (
                <Card
                  className="train-card"
                  key={index}
                  sx={{ minWidth: "200px" }}
                >
                  <CardContent>{Icon}</CardContent>
                </Card>
              ))}
            </Box>
          </Box>
          <Box sx={{ paddingTop: "60px", textAlign: "center" }}>
            <Typography
              className="about-heading"
              sx={{ fontSize: "24px", color: "#FF9357", fontWeight: "500, " }}
              variant="h5"
            >
              ABOUT SOFTKODE
            </Typography>
            <Typography
              className="about-heading"
              sx={{ fontSize: "28px", padding: "20px", fontWeight: "500" }}
              variant="h6"
            >
              Streamlined processes boost efficiency and productivity
            </Typography>
            <Typography
              className="work-process"
              sx={{ fontSize: "18px", color: "black", fontWeight: "500" }}
              variant="h5"
            >
              Work Process
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Hero
              style={{ height: "40%", width: "80%", margin: "20px auto" }}
            />
            {[
              "Discovery and Planning",
              "UI & UX",
              "Development",
              "Deployment and Launch",
              "Quality Assurance",
              "Support and Maintenance",
            ].map((step, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "10px",
                  marginLeft: "10%",
                }}
              >
                <Iconcheckmark style={{ marginRight: "10px" }} />
                <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
                  {step}
                </Typography>
              </Box>
            ))}
          </Box>
          <Container
            sx={{
              backgroundColor: "#F2F7FF",
              py: "0",
              maxWidth: "100% !important",
              textAlign: "center",
            }}
          >
            <Box sx={{ padding: "40px" }}>
              <Typography
                sx={{ fontSize: "28px", color: "#FF9357", fontWeight: "500" }}
                variant="h6"
              >
                OUR CLIENTS
              </Typography>
              <Grid container spacing={2} sx={{ py: 3 }}>
                {[Dataeaze, NTT, Tata, Enerscript].map((ClientLogo, index) => (
                  <Grid item xs={6} key={index}>
                    <Box className="client-logo">
                      <ClientLogo />
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2}>
                {[
                  { text: "10+", label: "Professional" },
                  { text: "12k+", label: "Service Closed" },
                  { text: "5+", label: "Years Experience" },
                  { text: "100%", label: "Customer Satisfaction" },
                ].map((item, index) => (
                  <Grid item xs={6} key={index}>
                    <Box className="textClients">{item.text}</Box>
                    <Box className="client-char">{item.label}</Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
        </Box>
      ) : (
        // Desktop HTML structure
        <Box>
          <Typography
            sx={{ fontSize: "24px", color: "#FF9357", fontWeight: "500" }}
            variant="h5"
          >
            OUR TECHNOLOGIES
          </Typography>
          <Typography
            sx={{ fontSize: "44px", padding: "20px", fontWeight: "500" }}
            variant="h6"
          >
            Trusted Software Service Providers
          </Typography>
          <Box className="train-container">
            <Box className="train-content">
              <Card className="train-card">
                <CardContent>
                  <Google className="image" />
                </CardContent>
              </Card>
              <Card className="train-card">
                <CardContent>
                  <Aws className="image" />
                </CardContent>
              </Card>
              <Card className="train-card">
                <CardContent>
                  <Azure className="image" />
                </CardContent>
              </Card>
              <Card className="train-card">
                <CardContent>
                  <Git className="image" />
                </CardContent>
              </Card>
              <Card className="train-card">
                <CardContent>
                  <Docker className="image" />
                </CardContent>
              </Card>
              <Card className="train-card">
                <CardContent>
                  <Visual className="image" />
                </CardContent>
              </Card>
              {/* Duplicate cards for seamless loop */}
              <Card className="train-card">
                <CardContent>
                  <Google className="image" />
                </CardContent>
              </Card>
              <Card className="train-card">
                <CardContent>
                  <Aws className="image" />
                </CardContent>
              </Card>
              <Card className="train-card">
                <CardContent>
                  <Azure className="image" />
                </CardContent>
              </Card>
              <Card className="train-card">
                <CardContent>
                  <Git className="image" />
                </CardContent>
              </Card>
              <Card className="train-card">
                <CardContent>
                  <Docker className="image" />
                </CardContent>
              </Card>
              <Card className="train-card">
                <CardContent>
                  <Visual className="image" />
                </CardContent>
              </Card>
            </Box>
          </Box>
          <Container
            sx={{
              paddingTop: "120px",
              marginTop: "10%",
              maxWidth: "100% !important",
            }}
          >
            <Box>
              <Typography
                sx={{ fontSize: "24px", color: "#FF9357", fontWeight: "500" }}
                variant="h5"
              >
                ABOUT SOFTKODE
              </Typography>
              <Typography
                sx={{
                  fontSize: "44px",
                  padding: "20px",
                  fontWeight: "500",
                  whiteSpace: "nowrap",
                }}
                variant="h6"
              >
                Streamlined processes boost efficiency and productivity
              </Typography>
              <Typography
                sx={{ fontSize: "18px", color: "black", fontWeight: "500" }}
                variant="h5"
              >
                Work Process
              </Typography>
            </Box>
          </Container>
          <Grid container spacing={3} direction="row">
            <Grid item xs={12} md={6}>
              <Hero
                style={{
                  height: "100%",
                  width: "100%",
                  bottom: "16%",
                  left: "4%",
                  position: "relative",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                left: "5%",
                position: "relative",
              }}
            >
              {[
                "Discovery and Planning",
                "UI & UX",
                "Development",
                "Deployment and Launch",
                "Quality Assurance",
                "Support and Maintenance",
              ].map((step, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "30px",
                    justifyContent: "left",
                  }}
                >
                  <Iconcheckmark
                    style={{
                      right: "4%",
                      position: "relative",
                    }}
                  />
                  <Typography
                    sx={{ padding: "4px", fontWeight: "600", fontSize: "20px" }}
                  >
                    {step}
                  </Typography>
                </Box>
              ))}
            </Grid>
          </Grid>
          <Container
            sx={{
              backgroundColor: "#F2F7FF",
              py: "0",
              maxWidth: "100% !important",
            }}
          >
            <Box sx={{ marginTop: "-5.3%", padding: "80px" }}>
              <Typography
                sx={{ fontSize: "20px", color: "#FF9357", fontWeight: "500" }}
                variant="h6"
                align="center"
              >
                OUR CLIENTS
              </Typography>
              <Grid container spacing={2} sx={{ py: 3 }}>
                {[Dataeaze, NTT, Tata, Enerscript].map((ClientLogo, index) => (
                  <Grid item xs={6} sm={3} key={index}>
                    <Box className="client-logo">
                      <ClientLogo />
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2}>
                {[
                  { text: "10+", label: "Professional" },
                  { text: "12k+", label: "Service Closed" },
                  { text: "5+", label: "Years Experience" },
                  { text: "100%", label: "Customer Satisfaction" },
                ].map((item, index) => (
                  <Grid item xs={6} sm={3} key={index}>
                    <Box className="textClients">{item.text}</Box>
                    <Box className="client-char">{item.label}</Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
        </Box>
      )}
    </Container>
  );
};

export default About;
