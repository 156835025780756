import React from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactComponent as Illustration } from "./assets/Illustration.svg";

const Home = () => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container
      sx={{ py: 10, backgroundColor: "#F2F7FF", maxWidth: "100% !important" }}
    >
      {isSmallDevice ? (
        // Mobile HTML structure
        <Grid container spacing={2} sx={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              sx={{
                fontSize: "28px",
                paddingTop: "10%",
                width: "100%",
              }}
            >
              Custom Software Solutions
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "18px",
                marginTop: "10px",
                paddingLeft: "5%",
                paddingRight: "5%",
              }}
            >
              We deliver impactful digital solutions across industries.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Illustration
              style={{
                height: "40%",
                width: "80%",
                margin: "20px auto",
              }}
            />
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#FF9357",
                color: "white",
                height: "45px",
                width: "60%",
                borderRadius: "10px",
                marginTop: "20px",
              }}
            >
              Contact Us
            </Button>
          </Grid>
        </Grid>
      ) : (
        // Desktop HTML structure
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box
              sx={{ display: "flex", flexDirection: "column", padding: "15%" }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: "50px",
                  paddingLeft: "8%",
                  lineHeight: "1.2",
                  textAlign: "left",
                  paddingTop: "5%",
                  width: "68%",
                }}
              >
                Custom Software and Digital Products to Ignite your Business
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "25px",
                  paddingLeft: "8%",
                  textAlign: "left",
                  width: "53%",
                  marginTop: "4%",
                }}
              >
                We are a cross-disciplinary team, blending our expertise to
                deliver impactful digital solutions across industries.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FF9357",
                  color: "white",
                  height: "65px",
                  width: "30%",
                  borderRadius: "12px",
                  marginTop: "4%",
                  marginLeft: "8%",
                }}
              >
                Contact
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Illustration
              style={{
                height: "55%",
                width: "100%",
                right: "14%",
                top: "17%",
                position: "relative",
              }}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default Home;
