import emailjs from "emailjs-com";
import React, { useState } from "react";
import "./Contact.css"; // Import the CSS file

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });
  const [alert, setAlert] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);

    emailjs.init("lvmHbjiJ-ympdLWPu");
    emailjs.send("service_email", "template_email_id", formData).then(
      (result) => {
        setAlert({ type: "success", message: "Message sent successfully!" });
        setFormData({ name: "", email: "", contact: "", message: "" });
      },
      (error) => {
        setAlert({
          type: "error",
          message: "Failed to send the message. Please try again later.",
        });
      }
    );
  };

  return (
    <div className="container">
      <div className="form-container">
        <div className="contact-info">
          <img src="contact.svg" alt="Contact Us" className="contact-image" />
          <ul className="contact-details">
            <li className="contact-hero">
              <strong>
                <img src="phone.svg" alt="phone" />
              </strong>{" "}
              +91 8237989969
            </li>
            <br />
            <li className="contact-hero">
              <strong>
                <img src="email.svg" alt="email" />
              </strong>
              krishnasharma@softkode.io
            </li>
            <br />
            <li className="contact-hero">
              <strong>
                <img src="location.svg" alt="address" />
              </strong>
              India, Pune, Maharashtra, 411015
            </li>
          </ul>
        </div>
        <div className="form-section">
          <h2 className="header">Get in Touch</h2>
          <p>Any questions or remarks? Let us know!</p>

          {alert && (
            <div
              className={
                alert.type === "success" ? "alert-success" : "alert-error"
              }
            >
              {alert.message}
            </div>
          )}

          <form onSubmit={handleSubmit} className="contact-form">
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleChange}
              className="input"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              className="input"
              required
            />
            <input
              type="text"
              name="contact"
              placeholder="Enter your Contact"
              value={formData.contact}
              onChange={handleChange}
              className="input"
              required
            />
            <textarea
              name="message"
              placeholder="Enter your message"
              value={formData.message}
              onChange={handleChange}
              className="textarea"
              required
            />
            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
