import React from "react";
import { Link as ScrollLink } from "react-scroll";
import "./Footer.css";
const App = () => {
  return (
    <div className="app">
      <section className="testimonials">
        <h2>TESTIMONIAL</h2>
        <h3>What Our Customers Say</h3>
        <div className="testimonial-cards">
          <div className="testimonial-card">
            <p>
              "Softkode Technologies has been instrumental in delivering
              high-quality software solutions for our business. Their skilled
              developers bring innovative ideas to life, ensuring our projects
              are completed on time and to the highest standard."
            </p>
            <h4>Ajit Ratnaparkhi</h4>
            <span>Customer</span>
          </div>
          <div className="testimonial-card">
            <p>
              "We are extremely satisfied with the exceptional software
              development services provided by Softkode Technologies. Their team
              of developers is highly skilled, responsive, and committed to
              delivering results that align perfectly with our business goals."
            </p>
            <h4>Sushil Shinde</h4>
            <span>Customer</span>
          </div>
          <div className="testimonial-card">
            <p>
              "We highly value the collaboration with Softkode Technologies
              their developers are not only proficient but also bring a creative
              approach to solving complex software challenges."
            </p>
            <h4>Sushant Shinde</h4>
            <span>Customer</span>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-logo">
            <h2>Softkode Technologies Pvt. Ltd.</h2>
            <p>India, Pune, Mahrahstra, 411015</p>
            <p>8237989969</p>
            <p>krishnasharma@softkode.io</p>
          </div>
          <div className="footer-links">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <ScrollLink
                  to="home"
                  smooth={true}
                  duration={500}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <p>Home</p>
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="about"
                  smooth={true}
                  duration={500}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <p>  About Us </p>
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="about"
                  smooth={true}
                  duration={500}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <p>   Services </p>
                </ScrollLink>{" "}
              </li>
              <li>
                <ScrollLink
                  to="contact"
                  smooth={true}
                  duration={500}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <p> Contact </p>
                </ScrollLink>
              </li>
            </ul>
          </div>
          <div className="footer-company">
            <h3>Company</h3>
            <ul>
              <li>
                <a href="/story">Our Story</a>
              </li>
              <li>
                <a href="/team">Team</a>
              </li>
              <li>
                <a href="/terms">Terms Of Service</a>
              </li>
              <li>
                <a href="/support">Support</a>
              </li>
              <li>
                <a href="/faq">FAQ</a>
              </li>
            </ul>
          </div>
          <div className="footer-contact">
            <h3>Keep in Touch</h3>
            <form>
              <input type="email" placeholder="Email address" />
              <button type="submit">Subscribe</button>
            </form>
            <div className="social-icons">
              <a href="/">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="/">
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Sodtkode Technologies Pvt. Designed by Softkode</p>
        </div>
      </footer>
    </div>
  );
};

export default App;
