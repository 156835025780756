// src/Navbar.js
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { Link } from "react-scroll";
import { ReactComponent as Cube } from "./assets/Cube.svg";
import "./navbar.css";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#524BE3", zIndex: "1" }}>
      <Toolbar>
        <Box
          className="navLogo"
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            marginLeft: "10%",
          }}
        >
          <Cube
            style={{
              marginRight: "8px",
              padding: "0px",
              display: "block",
            }}
          />
          <Typography
            className="navNameMob"
            variant="h6"
            component="div"
            sx={{ fontSize: "50px" }}
          >
            Skode
          </Typography>
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              md: "block",
              marginRight: "10%",
            },
          }}
        >
          <Button sx={{ fontSize: "20px" }} color="inherit">
            <Link
              to="home"
              smooth={true}
              duration={500}
              class
              className="nav-link"
            >
              Home
            </Link>
          </Button>
          <Button sx={{ fontSize: "20px" }} color="inherit">
            <Link
              to="about"
              smooth={true}
              duration={500}
              class
              className="nav-link"
            >
              About
            </Link>
          </Button>
          <Button sx={{ fontSize: "20px" }} color="inherit">
            <Link
              to="services"
              smooth={true}
              duration={500}
              class
              className="nav-link"
            >
              Services
            </Link>
          </Button>

          <Button sx={{ fontSize: "20px" }} color="inherit">
            <Link
              to="contact"
              smooth={true}
              duration={500}
              class
              className="nav-link"
            >
              Contact
            </Link>
          </Button>
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>
              <Link to="home" smooth={true} duration={500}>
                Home
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <Link to="services" smooth={true} duration={500}>
                Services
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <Link to="about" smooth={true} duration={500}>
                About
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <Link to="contact" smooth={true} duration={500}>
                Contact
              </Link>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
